import React from 'react'
import SEO from '../components/seo'
import News from '../components/News'
import { useSolutionsData } from '../hooks/useSolutionsData'
import { useTranslations } from '../hooks/use-translations'
import SgnxImageSeo from '../assets/images/seo/sgnx/solutions.png'

const SolutionsPage: React.FC = () => {
  const { t } = useTranslations()
  const title = t('Solutions')
  const solutions = useSolutionsData()
  return (
    <>
      <SEO
        title={title}
        image={SgnxImageSeo}
        description="Unlock the power of cutting-edge technology with our innovative solutions tailored to your needs. Explore our suite of services and take your enterprise to new heights."
      />
      <News title={title} items={solutions} />
    </>
  )
}

export default SolutionsPage
